import React, { Component } from "react";
function myRandomInts(quantity, max){
    const arr = []
    while(arr.length < quantity){
      var candidateInt = Math.floor(Math.random() * max) + 1
      if(arr.indexOf(candidateInt) === -1) arr.push(candidateInt)
    }
  return(arr)
}
class Testimonials extends Component {
  render() {
    if (!this.props.data) return null;

    const testimonials = this.props.data.testimonials.map(function(testimonials) {
      return (
        <li key={testimonials.user}>
          <blockquote>
            <p>{testimonials.text}</p>
            <cite>{testimonials.user}</cite>
          </blockquote>
        </li>
      );
    });

    const numbers = myRandomInts(4, testimonials.length);

    return (
      <section id="testimonials">
        <div className="text-container">
          <div className="row">
            <div className="two columns header-col">
              <h1>
                <span>Testimoniale clienți
                </span>
              </h1>
            </div>

            <div className="ten columns flex-container">
              <ul className="slides">{testimonials[numbers[0]]}</ul>
              <ul className="slides">{testimonials[numbers[1]]}</ul>
              <ul className="slides">{testimonials[numbers[2]]}</ul>
              <ul className="slides">{testimonials[numbers[3]]}</ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Testimonials;
