import React, { Component } from "react";
import Fade from "react-awesome-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const link1 = this.props.data.link1;
    const link2 = this.props.data.link2;
    const link3 = this.props.data.link3;
    const link4 = this.props.data.link4;
    const link5 = this.props.data.link5;
    const description = this.props.data.description;
    const profilepic = "images/" + this.props.data.image;
    let gif = require('../images/kfy.mp4')

    return (
      <header id="home" className="viewport-header">
        
        <video id="background-video" autoPlay loop muted playsInline>
            <source src={gif} type='video/mp4'/>
        </video>
        
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Acasa
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                Despre
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#resume">
                Servicii
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#portfolio">
                Poze Lucrari
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#testimonials">
                Testimoniale
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              
              <img
                className="profile-pic-header"
                src={profilepic}
                alt="Kfy Logo"
              />
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}</h3>
            </Fade>
            <hr />
            <Fade bottom duration={2000}>
              <ul className="social">
                <a href={link1} className="button btn github-btn">
                  <i className="fa-brands fa-whatsapp"></i>WhatsApp - 0762 626 185
                </a>
                <a href={link2} className="button btn github-btn">
                  <i className="fa-brands fa-whatsapp"></i>WhatsApp - 0760 703 031
                </a>
                <a href={link3} className="button btn github-btn">
                  <i className="fa-brands fa-tiktok"></i>TikTok
                </a>
                <a href={link4} className="button btn github-btn">
                  <i className="fa-brands fa-facebook"></i>Facebook
                </a>
                <a href={link5} className="button btn github-btn">
                  <i className="fa-brands fa-instagram"></i>Instagram
                </a>
              </ul>
            </Fade>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
