import React, { Component } from "react";
import Fade from "react-awesome-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone1 = this.props.data.phone1;
    const phone2 = this.props.data.phone2;
    const email1 = this.props.data.email1;
    const email2 = this.props.data.email2;


    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Kfy Logo"
              />
              <br />&nbsp;
              </div>
            <div className="nine columns main-col">  
              <div className="row">
                <div className="columns contact-details">
                  <h2>Detalii contact</h2>
                  <p className="address">
                    <span>Nume: {name}</span>
                    <br />&nbsp;
                    <span>
                      Adresa: {street}
                      <br />&nbsp;
                      {city}, {state}, {zip}
                    </span>
                    <br />&nbsp;
                    <span>Telefon: {phone1}</span>
                    <br />&nbsp;
                    <span>Telefon: {phone2}</span>
                    <br />&nbsp;
                    <span>Email: {email1}</span>
                    <br />&nbsp;
                    <span>Email: {email2}</span>
                  </p>
                </div>
                <div className="columns download">
                 
                  <div className="map-responsive">
                    <iframe title="Harta service" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4051.395432890498!2d24.32437205081539!3d44.108754436042126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ace186dba80473%3A0xbf0bbaa8ce717bb1!2sKfy%20Auto!5e0!3m2!1sen!2sro!4v1672838740447!5m2!1sen!2sro" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
