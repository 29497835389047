import React, { Component } from "react";
import Slide from "react-awesome-reveal";

class Servicii extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;
    
    const serviciu = this.props.data.serviciu.map(function (serviciu) {
      let projectImage = "images/portfolio/" + serviciu.image;
      
      return (
        <div key={serviciu.school}>
          <h3>{serviciu.serv}</h3>
          <p>{serviciu.descriere}</p>
      
        </div>
      );
    });

    const detailing = this.props.data.detailing.map(function (detailing) {
      return (
        <div key={detailing.company}>
          <h3>{detailing.munk}</h3>
          <p>{detailing.descriere}</p>
        </div>
      );
    });

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Servicii</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{serviciu}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Detailing Auto</span>
              </h1>
            </div>

            <div className="nine columns main-col">{detailing}</div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Servicii;
